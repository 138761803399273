@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.containerAnimation {
  margin-top: 110px;
  margin-bottom: $sectionMarginBottom;
  row-gap: 30px;
  
  @media screen and (min-width: $breakpoint-lg) {
    margin-top: 160px;
  }
}

.flexImages {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.gif {
  margin: auto auto;
  width: 35em;
}

.textBlock {
  display: flex;
  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}

.banner {
  width: 90%;
  margin: 35px auto 0 auto;
  width: 60%;
}

.mainTxt {
  color: $blue-3;
  font-size: $fontSectionTitle;
  font-weight: bold;
  margin: 0 auto;
}

.highlight {
  color: $green-1;
}

.txt {
  font-size: $fontSectionDescription;
  color: $blue-3;
  margin: 11px auto 0 auto;
}


@media screen  and (min-width: $breakpoint-mini){
  .gif{  
    width: 23em;
    margin-top: 15px;
  }

  .banner{
    width: 90%;
    margin-top: 37px;
  }
}


@media screen  and (min-width: $breakpoint-xs){
  .gif{  
    width: 23em;
    margin-top: 15px;
  }

  .banner{
    width: 90%;
    margin-top: 37px;
  }
}

@media screen  and (min-width: $breakpoint-sm){
  .gif{  
    width: 23em;
    margin-top: 15px;
  }

  .banner{
    width: 80%;
    margin-top: 37px;
  }
}

@media screen  and (min-width: $breakpoint-md){
  .gif{  
    width: 23em;
    margin-top: 15px;
  }

  .banner{
    width: 80%;
    margin-top: 37px;
  }
}

@media screen  and (min-width: $breakpoint-lg){
  .gif{
    width: 17em;
    margin-top: 75px;
  }

  .banner{
    width: 50%;
    margin-top: 15px;
  }
}

@media screen  and (min-width: $breakpoint-xlg){
  .gif{
    width: 19em;
    margin-top: 50px;
  }

  .banner{
    width: 50%;
    margin-top: 15px;
  }
}

@media screen  and (min-width: $breakpoint-xl){
  .gif{
    width: 20em;
    margin-top: 40px;
  }

  .banner{
    width: 48%;
    margin-top: 15px;
  }
}

@media screen  and (min-width: $breakpoint-xxl){
  .gif{
    width: 24em;
    margin-top: 30px;
  }

  .banner{
    width: 45%;
    margin-top: 15px;
  }
}

@media screen  and (min-width: $breakpoint-xxxl){
  .gif{
    width: 27em;
    margin-top: 10px;
  }

  .banner{
    width: 38%;
    margin-top: 15px;
  }
}
