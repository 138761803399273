@import "../../../../styles/variables.scss";

.container{
  width: 75%;
  margin: 0 auto;
  border: 3px solid $gray-2;
  padding: 15px 10px 30px 10px;
}

.wrapper {
  margin: 0 auto;
}

.wrapper > * {
  height: 85px;
}

.submitSection{
  margin-top: 50px;
}

.submitButton{
  display:inline;
  position:relative;
}

.submitButtonOver {
  width: 500px;
  font-weight: bold !important;
  font-size: 20px !important;
  &:hover {
    background-color: $blue-2 !important;
  }
  
  @media screen and (max-width: $breakpoint-xxl) {
    width: 370px;
  }
  
  @media screen and (max-width: $breakpoint-lg) {
    width: 350px;
  }
  
  @media screen and (max-width: $breakpoint-md) {
    width: 300px;
  }
  
  @media screen and (max-width: $breakpoint-sm) {
    width: 210px !important;
  }

  @media screen and (max-width: $breakpoint-xs) {
    width: 180px !important;
  }
}

.submitNote{
  position:absolute;
  top: -100%;
  left: 65%;
  z-index: 100;
  width: 150px;
  font-size: 15px;
  color: $errorColor;
}

@media screen and (max-width: $breakpoint-sm) {
  .submitButtonOver {
    width: 290px;
    font-size: 16px !important;
  }

  .submitNote{
    top: -65%;
    font-size:11px;
    width:100px;
  }
}
