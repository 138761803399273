@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
.container {
  width: 80%;
  max-width: 300px;
  position: relative;
  // min-height: 250px;
  border-radius: 30px;
  padding: 65px 25px 25px 15px;
  margin-top: 50px;
  margin-right: 0;
  border: 2px solid;
  @include content-shadow;
  @include remove-shadow-viewport;

  &:hover .image {
    height: 110px;
    transition: height 0.2s linear;
  }

  .image {
    height: 100px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: -50px;
    transition: height 0.2s linear;
  }

  .title {
    font-size: $fontCardTitle;
    font-weight: 700;
    margin-bottom: 20px;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 20px;
    }
  }

  .button{
    margin: 21px 0 5px 0;
  }
}
