@import "../../styles/variables.scss";

.container {
  align-items: center;
  margin-bottom: 60px;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint-xxl ) {
    width: 70%;
  }

  @media screen and (max-width: $breakpoint-xxl) {
    width: 80%;
  }

  @media screen and (max-width: $breakpoint-md) {
    width: 90%;
  }


  &.flexDistribution {
    display: flex;
    flex-direction: column;
  }

  .header {
    font-size: $fontSectionTitle;
    margin-bottom: 17px;
    font-weight: 800;
    color: $green-2;
  }

  .description {
    color: $blue-3;
    font-size: $fontSectionDescription;
    font-weight: 400;
    width: 90%;
    margin: 0 auto;

    &.flexDistribution {
      margin-bottom: 30px;
    }

    &.blockDistribution {
      margin: 0 auto 30px auto;
    }
  }

  .children {
    width: 100%;

    &.blockDistribution {
      margin: 0 auto;
    }

    &.flexDistribution {
      display: flex;
      justify-content: center;
    }
  }
}
