@import "../../../../styles/variables.scss";

.teamContainer {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 60px;
  margin-bottom: 45px;
}
