@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include width-viewport;
  margin-bottom: 60px;

  .carouselContainer {
    width: 100%;
    box-sizing: border-box;
  }

  .cardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: $breakpoint-xxl) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-xlg) {
  .container {
    width: 100%;
    flex-direction: row;
    min-height: 200px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .container {
    width: 100%;
  }
}