@import "../../styles/variables.scss";

  .container {
    display: flex;
    align-items: center;
    padding: 20px 0 20px 0;

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 897px;
      
      &.rowReverse {
        flex-direction: row-reverse;
      }

      .image {
        height: 100%;
        margin: 20px;
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    
        max-width: 550px;
        
        text-align: left;
        font-size: 24px;
        line-height: 28px;
        
        padding: 0 15px 0 15px;
        
        &.rowReverse {
          padding: 0 0px 0 60px;
          font-size: 24px;
          line-height: 28px;
          text-align: left;
          padding: 0;
        }

        .title {
          font-size: $fontCardTitle;
          margin-bottom: 15px;
          font-weight: 700;
        }
        
        .description {
          font-size: $fontCardDescription;
          color: $sectionDescriptionColor;
          font-weight: $fontWeighCardDescription;
          margin: 0px 40px 0 25px ;
        }
      }

      .borderContainer {
        position: absolute;
      }
    }
  }
