@import "../../../../styles/variables.scss";

.container {
  display: flex;
  margin: 30px 0 100px 0;
  justify-content: space-around;
  width: 90%;
  flex-wrap: wrap;
  row-gap: 30px;
  .logo {
    height: 120px;
  }


  @media screen  and (min-width: $breakpoint-mini){
    .logo{
      height: 100px;
    }
  }
  
  @media screen  and (min-width: $breakpoint-xs){
    .logo{
      height: 110px;
    }
  }

  @media screen  and (min-width: $breakpoint-sm){
    .logo{
      height: 145px;
    }
  }

}



