@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 40px 30px;
  border-radius: 30px;
  @include content-shadow;
  @media screen and (min-width: $breakpoint-lg) {
    width: 16em;
  }

  &:hover {
    cursor: pointer;
  }

  .title {
    margin-bottom: 20px;
    max-height: 60px;
  }

  .subtitle{
    color:$blue-3;
    margin-bottom: 3px;
    font-size: $fontSectionDescription;
    @media screen and (min-width: $breakpoint-lg) {
      min-height: 81px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      max-height: 110px;
    }

    .description {
      text-align: left;
      color: $blue-3;
      width: 62%;
      font-weight: 400;
      font-size: 20px;
    }
  }
  
  ul{
    text-align: left;
    font-size: $fontSectionDescription;
    @media screen and (min-width: $breakpoint-lg) {
      min-height: 215px;
    }
  }
  
  li{
    color: $sectionDescriptionColor;
    font-weight: $fontWeighCardDescription;
    margin: 15px 0;
  }
}

@media screen and (max-width: $breakpoint-xlg) {
  .container {
    padding: 20px 20px 45px 20px;
    .title {
      max-height: 60px;
    }
  }
}
