@import "../../styles/variables.scss";

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    border-radius: 16px;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    font-size: $fontCardDescription;
    color: white;

    @media screen and (min-width: $breakpoint-md) {
      width: 50%;
      padding: 25px 41px;
    }

    @media screen and (min-width: $breakpoint-xl) {
      width: 80%;
      padding: 25px 15px;
    }

  }

  .opinion{
    margin:25px 0 0 13px;
  }

  .triangle {
    height: 0;
    width: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 40px solid;
  }
}
