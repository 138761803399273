@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  .slidesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;

    .arrow {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .clickableArea {
        width: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
      }
    }
    .slice {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .children {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .dots {
    display: flex;
    height: 10px;
    margin-top:50px;
    .dot {
      margin: 0 15px;
      cursor: pointer;
      height: 5px;
      width: 5px;
      border-radius: 2.5px;
      background: $blue-2;

      &.selected {
        background: $green-1;
      }
    }
  }
}
