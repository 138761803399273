@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';


// .mainContainer{
//   @media screen  and (min-width: $breakpoint-mini){
//     background-color: red;
//   }

//   @media screen  and (min-width: $breakpoint-xs){
//     background-color: lightpink;
//   }

//   @media screen  and (min-width: $breakpoint-sm){
//     background-color: lightseagreen;
//   }

//   @media screen  and (min-width: $breakpoint-md){
//     background-color: green;
//   }

//   @media screen  and (min-width: $breakpoint-lg){
//     background-color: lightyellow;
//   }

//   @media screen  and (min-width: $breakpoint-xlg){
//     background-color: lightsteelblue;
//   }

//   @media screen  and (min-width: $breakpoint-xl){
//     background-color: lightgoldenrodyellow;
//   }

//   @media screen  and (min-width: $breakpoint-xxl){
//     background-color: lightslategray;
//   }

//   @media screen  and (min-width: $breakpoint-xxxl){
//     background-color: lightsteelblue;
//   }
// }

.navbar{
  width: 100vw;
  position:fixed;
  background: $gray-1;
  top: 0;
  z-index: 3;
}