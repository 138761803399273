@import "../../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.bookingButton {
  width: 500px;
  font-weight: bold !important;
  font-size: 20px !important;
  &:hover {
    background-color: $blue-2 !important;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .bookingButton {
    width: fit-content;
    font-size: 16px !important;
  }
}

.contactLinksContainer {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  margin: 30px 0 15px 0;

  .linkedInButton {
    font-weight: 700;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    margin-right: 2vw;
    margin-top:1px;
    padding: 5px 2px 0 2px;
    border: $blue-2 solid 3px;
    border-radius: 30px;
    color: $blue-2;

    &:hover {
      color: white;
      background: $blue-2;
      border: $blue-2 solid 3px;
    }
  }

  .whatsappButton {
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    margin-left: 2vw;
    background-image: url("../../../../assets/PungoIcons/Whatsapp.svg");
    background-repeat: no-repeat;
    background-size: contain;
    &:hover {
      color: $gray-1;
      background-image: url("../../../../assets/PungoIcons/WhatsappHover.svg");
      cursor: pointer;
    }
  }
}

.copyright {
  margin-bottom: 30px;
  font-size: 20px;
}
