@import "../../../styles/variables.scss";

.section {
  display: flex;
  font-size: $fontCardDescription;
  align-items: space-between;
  margin-top: 20px;

  .text {
    text-align: left;
    margin-left: 25px;
    color: $sectionDescriptionColor;
    font-weight: $fontWeighCardDescription;
    @media screen and (max-width: $breakpoint-lg) {
      margin-left: 12px;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 18px;
  }
}
