@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 125px 0 100px 0;
  @include width-viewport;

  .carouselContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .cardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media screen  and (min-width: $breakpoint-sm){
  .container{
    margin: 0 0 50px 0;
  }
}

@media screen  and (min-width: $breakpoint-md){
  .container{
    margin: 15px 0 50px 0;
  }
}


