@import "../../styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  padding: 40px 0 10px 0;

  .content {
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    width: 245px;
    
    .borderContainer {
      position: absolute;
    }

    .image {
      height: 72px;
    }

    .textContainer {
      text-align: center;
      line-height: 1.4;
      .title {
        font-size: $fontCardDescription;
        margin: 5px 0 5px 0;
        font-weight: 700;
        line-height: 1;
      }

      .description {
        color: $sectionDescriptionColor;
        font-size:$fontCardDescription;
        font-weight: $fontWeighCardDescription;
        margin: 0px 5px ;
        line-height: 1.1;
      }
    }
  }
}
